import React, { useState, useEffect } from 'react';
import { TagBox, SelectBox, Button } from 'devextreme-react';
import { ProgressBar } from 'react-bootstrap';
import * as Fnctn from '../../contexts/GenericFunctions'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { DataGridTypes } from "devextreme-react/data-grid"
import '../ReportTasks/ReportTasks.scss'
import  { viewFile, enumPath } from '../../contexts/FirebaseActions'

interface PropsSucursales {
    getIds: (e: any, tb: string) => void;
}

const TagBoxSucursales: React.FC<PropsSucursales> = ({ getIds }) => {
    const [sucursales, setSucursales] = useState<any>();

    useEffect(() => {
        getSucursales();
    }, []);

    const getSucursales = async () => {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/catalogs/branches/?cvecia=007`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        dtResult = await dtResult.json();

        setSucursales(dtResult.data);
        let e: any = { value: "result" }
        itemclick(e, "preload", "sucursales", dtResult.data)
        halcoloaderRemove();
    }

    const itemclick = async (e: any, mode: string, tb: string = "sucursales", optionalarray: any[] = []) => {
        if (mode === "single") {
            let arrayIds: any = [];
            await e.component.option('selectedItems').forEach((element: any) => { arrayIds.push(element.code_branch); });
            getIds(arrayIds, tb);
        }
        else if ((mode === "all")) {
            if (e.value) {
                let arrayIds: any = [];
                await sucursales.forEach((element: any) => { arrayIds.push(element.code_branch); });
                getIds(arrayIds, tb);
            }
            else {
                getIds([], tb);
            }
        }
        else if ((mode === "preload")) {
            let arrayIds: any = [];
            await optionalarray.forEach((element: any) => { arrayIds.push(element.code_branch); });
            getIds(arrayIds, tb);
        }
    }

    return (
        <TagBox
            className='tbSucursales'
            dataSource={sucursales}
            label='Sucursales'
            showSelectionControls={true}
            searchEnabled={true}
            multiline={false}
            showMultiTagOnly={false}
            displayExpr={"name"}
            valueExpr={"_id"}
            onItemClick={(e: any) => itemclick(e, "single")}
            onSelectAllValueChanged={(e: any) => itemclick(e, "all")}
        ></TagBox>
    );
};

interface PropsAreas {
    getIds: (e: any, tb: string) => void;
}

const TagBoxAreas: React.FC<PropsAreas> = ({ getIds }) => {
    const [areas, setAreas] = useState<any>();

    useEffect(() => {
        getAreas();
    }, []);

    const getAreas = async () => {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/catalogs/areas`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        dtResult = await dtResult.json();

        setAreas(dtResult.data);
        let e: any = { value: "result" }
        itemclick(e, "preload", "areas", dtResult.data)
        halcoloaderRemove();
    }

    const itemclick = async (e: any, mode: string, tb: string = "areas", optionalarray: any[] = []) => {
        if (mode === "single") {
            let arrayIds: any = [];
            await e.component.option('selectedItems').forEach((element: any) => { arrayIds.push(element._id); });
            getIds(arrayIds, tb);
        }
        else if ((mode === "all")) {
            if (e.value) {
                let arrayIds: any = [];
                await areas.forEach((element: any) => { arrayIds.push(element._id); });
                getIds(arrayIds, tb);
            }
            else {
                getIds([], tb);
            }
        }
        else if ((mode === "preload")) {
            let arrayIds: any = [];
            await optionalarray.forEach((element: any) => { arrayIds.push(element._id); });
            getIds(arrayIds, tb);
        }
    }
    

    return (
        <TagBox
            className='tbAreas'
            dataSource={areas}
            label='Areas'
            showSelectionControls={true}
            multiline={false}
            showMultiTagOnly={false}
            displayExpr={"name"}
            valueExpr={"_id"}
            onItemClick={(e: any) => itemclick(e, "single")}
            onSelectAllValueChanged={(e: any) => itemclick(e, "all")}
        ></TagBox>
    );
};

const CreateHeadersTasks = (props: any) => {
    const propiedadesCat: any = [];
    const headerlabels: any = [];

    for (const propiedad in props) {
        const matchCategory = propiedad.match(/category_name_cat_(\d+)_?.*$/);
        const matchPromedio = propiedad.match(/promedio_evaluaciones_cat_(\d+)_?.*$/);

        if (matchCategory) {
            propiedadesCat.push({
                nombre: propiedad,
                numero: parseInt(matchCategory[1]),
                valor: props[propiedad],
                dataField: `concatenated_cat_${matchCategory[1]}`,
                visible: true
            });
        }
        else if (matchPromedio) {
            propiedadesCat.push({
                nombre: "Promedio",
                numero: parseInt(matchPromedio[1]),
                valor: props[propiedad],
                dataField: `promedio_evaluaciones_cat_${matchPromedio[1]}`,
                visible: true
            });
        }
    }

    propiedadesCat.sort((a: any, b: any) => a.numero - b.numero);

    propiedadesCat.forEach((element: any) => {
        const matchCategoria = element.nombre.match(/category_name_cat_\d+_(\w{24})$/);
        const matchPromedio = element.nombre.match("Promedio");

        if (matchCategoria) {
            headerlabels.push(
                {
                    key: element.nombre,
                    dataField: element.dataField,
                    caption: element.valor,
                    visible: true,
                    category_id: matchCategoria[1],
                    type: "Progress",
                    width: "200px"
                }
            );
        }
        else if (matchPromedio) {
            headerlabels.push({
                key: element.nombre,
                dataField: element.dataField,
                caption: `Promedio`,
                visible: true,
                category_id: matchPromedio[1],
                format: { type: 'percent', precision: 2 },
                type: "Porcent",
                width: "110px"
            }
            );
        }
    });

    return headerlabels;
};

const CreateHeadersGeneralEvaluations = (props: any) => {
    const propiedadesCat: any = [];
    const headerlabels: any = [];

    for (const propiedad in props) {
        const matchCategory = propiedad.match(/category_name_cat_(\d+)_?.*$/);
        const matchPromedio = propiedad.match(/promedio_evaluaciones_cat_(\d+)_?.*$/);

        if (matchCategory) {
            propiedadesCat.push({
                nombre: propiedad,
                numero: parseInt(matchCategory[1]),
                valor: props[propiedad],
                dataField: `promedio_evaluaciones_cat_${matchCategory[1]}`,
                visible: false
            });
        }
        else if (matchPromedio) {
            propiedadesCat.push({
                nombre: "Promedio",
                numero: parseInt(matchPromedio[1]),
                valor: props[propiedad],
                dataField: `promedio_evaluaciones_cat_${matchPromedio[1]}`
            });
        }
    }

    propiedadesCat.sort((a: any, b: any) => a.numero - b.numero);

    propiedadesCat.forEach((element: any) => {
        const matchCategoria = element.nombre.match(/category_name_cat_\d+_(\w{24})$/);
        const matchPromedio = element.nombre.match("Promedio");

        if (matchCategoria) {
            headerlabels.push(
                {
                    key: element.nombre,
                    dataField: element.dataField,
                    caption: element.valor,
                    visible: true,
                    category_id: matchCategoria[1],
                    format: { type: 'percent', precision: 2 }
                }
            );
        }
        else if (matchPromedio) {
            headerlabels.push({
                key: element.nombre,
                dataField: element.dataField,
                caption: `Promedio`,
                visible: true,
                category_id: matchPromedio[1],
                format: { type: 'percent', precision: 2 }
            });
        }
    });

    return headerlabels;
};

const CellRenderProgressBar = (data: DataGridTypes.ColumnCellTemplateData, type: string) => {
    if (type === "Porcent") {
        return (<div>{data.text}</div>)
    }
    else if (type === "Progress") {
        let valores = data.displayValue,
            porcentProgress: number = 0,
            progressColor: string = "",
            newLabel: string = "";

        if (!Fnctn.NullUndefinedEmpty(valores)) {

            valores = valores.split("/")
            for (let i: number = 0; i < valores.length; i++) {
                valores[i] = parseInt(valores[i]);
            };
            porcentProgress = ((valores[0] / valores[1]) * 100);

            if (porcentProgress > 0 && porcentProgress <= 99) {
                progressColor = 'info';
                newLabel = data.value;
            }
            else if (porcentProgress === 100) {
                progressColor = 'success';
                newLabel = "Compleatado";
            }
            return (
                <ProgressBar
                    now={valores[0]}
                    max={valores[1]}
                    label={newLabel}
                    animated={true}
                    variant={progressColor}
                    style={{ fontSize: "10px" }}
                ></ProgressBar>
            )
        }
    }
};

const RenderHeader = (headerInfo: any) => {
    return (
        <div style={{ display: "flex", whiteSpace: "normal" }}>
            {headerInfo.column.caption}
        </div>
    );
};

const RenderCellDetail = (data: DataGridTypes.ColumnCellTemplateData, SetParamsDetail: () => void) => {
    let statusTask: string = data.data.onboarding_status;
    let fontColors: string = "";
    let mensaje: string = "";

    SetParamsDetail();

    if (statusTask === "Not Started") {
        fontColors = "#000";
        mensaje = "No iniciada";
    } else if (statusTask === "In Progress") {
        fontColors = "#0dcaf0";
        mensaje = "En progreso";
    } else if (statusTask === "Completed") {
        fontColors = "#3b996d";
        mensaje = "Completada";
    }
    
    return <label style={{ color: fontColors }}>{mensaje}</label>;
};

const getForm = async (params:any, GetDtForm:(data:any, headers:any) => void) => {
    halcoloaderActive();
    let api = Fnctn.GetAPI();
    let data = Fnctn.getCookie();

    let ObjtTasks: any = {
        "id_evaluacion": params.evaluation_id,
        "code_branches": params.code_branches,
        "ids_areas": params.id_areas,
        "cvetra": [params.cvetra]
    };

    let dtresult:any = await fetch(`${api}/reports/medicion_evaluaciones?filter_cvetra=true`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtTasks)
    });

    dtresult = await dtresult.json();
    
    let headers = CreateHeadersForms(dtresult.data[0]);

    GetDtForm([dtresult.data[0]], headers);
    halcoloaderRemove();
}

const CreateHeadersForms = (data:any) => {
    const newDtForm: any = [];
    const questions: any = [];
    const answers: any = [];
    const response_type: any = [];

    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            if (key.includes("question_")){
                questions.push({field:key, question:data[key]});
            }
            else if (key.includes("answer_")){
                answers.push({field:key, answer:data[key]});
            }
            else if (key.includes("response_type")){
                response_type.push({field:key, response_type:data[key]});
            }
        }
    }

    for(let i: number = 0; i < questions.length; i++){
        newDtForm.push({
            id:questions[i].field,
            question:questions[i].question,
            answer:answers[i].answer,
            response_type:response_type[i].response_type,
        });
    }
    return newDtForm
}

const ButtonForm = (params:any, GetDtForm:(data:any, headers:any) => void) => {
    return (<i className='dx-icon dx-icon-checklist btnEvaluacion' onClick={() => getForm(params, GetDtForm)}></i>)
}

const RenderDtForm = (headerInfo: any, type:string) => {
    if (type === "Question") {
        return (<div style={{ display: "flex", whiteSpace: "normal" }}>{headerInfo.data.question}</div>);
    }
    else {
        if(headerInfo.data.response_type === "file"){
            return (<Button className='btnViewFile' style={{ display: "flex", whiteSpace: "normal" }} text='VER DOCUMENTO' onClick={()=> viewFile(enumPath.ARCHIVOS_EVALUACIONES, headerInfo.data.answer)}></Button>);
        }
        else{
            return (<div style={{ display: "flex", whiteSpace: "normal" }}>{headerInfo.data.answer}</div>);
        }
    }
};

const CellRenderDtForm = (cellInfo: any) => {
    const keys: any = Object.keys(cellInfo.data);
    const lastKey = keys[keys.length - 2];
    const param = cellInfo.data[lastKey]

    if (cellInfo.column.customProperty === "file") {
        if (!Fnctn.NullUndefinedEmpty(param)) return (<Button className='btnViewFile' style={{ display: "flex", whiteSpace: "normal" }} text='VER DOCUMENTO' onClick={() => viewFile(enumPath.ARCHIVOS_EVALUACIONES, param)}></Button>);
        else return (<div></div>)
    }
    else {
        return (<div style={{ display: "flex", whiteSpace: "normal" }}>{cellInfo.displayValue}</div>);
    }
};

const CreeateHeadersGeneralForms = (data:any) => {
    const propiedadesQuestion: any = [];
    const headerlabels: any = [];

    for (const propiedad in data) {
        const matchQuestion = propiedad.match(/question_(\d+)_?.*$/);

        if (matchQuestion) {
            propiedadesQuestion.push({
                nombre: propiedad,
                numero: parseInt(matchQuestion[1]),
                valor: data[propiedad],
                dataField: `answer_${matchQuestion[1]}`,
                ResponseType: data[`response_type_${matchQuestion[1]}`],
                visible: false
            });
        }
    }

    propiedadesQuestion.sort((a: any, b: any) => a.numero - b.numero);

    propiedadesQuestion.forEach((element: any) => {
        const matchCategoria = element.nombre.match(/question_(\d+)_?.*$/);

        if (matchCategoria) {
            headerlabels.push(
                {
                    key: element.nombre,
                    dataField: element.dataField,
                    caption: element.valor,
                    visible: true,
                    ResponseType: element.ResponseType
                }
            );
        }
    });
    return headerlabels
}

interface PropsEvaluations {
    getIds: (e: any, tb: string) => void;
    getEvaluationName: (e: any, tb: string) => void;
}

const TextBoxEvaluations: React.FC<PropsEvaluations> = ({ getIds, getEvaluationName }) => {
    const [evaluations, setEvaluations] = useState<any>();

    useEffect(() => {
        getEvaluations();
    }, []);

    const getEvaluations = async () => {
        halcoloaderActive();

        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/training/evaluations_categoria`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });

        dtResult = await dtResult.json();
        dtResult = await [...dtResult.data].sort((a, b) => a.category_order - b.category_order);

        setEvaluations(dtResult);
        
        halcoloaderRemove();    
    }

    const itemclick = async (e: any, tb: string = "Evaluaciones") => {
        getIds(e.itemData._id, tb); 
        getEvaluationName(e.itemData.name_evaluation, tb); 
    }

    const RenderListElement = (e: any) => {
        return (
            <div className='tgEvaluacion'>
                <label className='tgCategorie'>{e.category_name}</label>
                <label className='tgTitle'>{e.name_evaluation}</label>
            </div>
        )
    }

    return (
        <SelectBox
            className='tbEvaluaciones'
            dataSource={evaluations}
            label='Evaluaciones'
            showSelectionControls={false}
            searchEnabled={true}
            displayExpr={"name_evaluation"}     
            valueExpr={"_id"}
            width={"25%"}
            onItemClick={(e: any) => itemclick(e, "Evaluaciones")}
            itemRender={RenderListElement}
        ></SelectBox>
    );
};

export {
    TagBoxSucursales
    , TagBoxAreas
    , CreateHeadersTasks
    , CreateHeadersGeneralEvaluations
    , CellRenderProgressBar
    , RenderHeader
    , RenderCellDetail
    , ButtonForm
    , RenderDtForm
    , CreeateHeadersGeneralForms
    , TextBoxEvaluations
    , CellRenderDtForm
}